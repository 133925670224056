
import { Vue, Component, Prop } from 'vue-property-decorator';
import CustomPopover from '@/modules/common/components/ui-kit/custom-popover.vue';
import { VClosePopover } from 'v-tooltip';

export interface IDropdownItem {
    text: string,
    link?: string,
    action?: Function,
    disable?: boolean,
    isVisible?: boolean,
}

@Component({
    components: {
        CustomPopover,
    },
    directives: { 'close-popover': VClosePopover },

})
export default class ProfileMenu extends Vue {
    @Prop({
        required: false,
        type: String,
    })
    label!: string;

    @Prop({
        required: false,
        type: String,
    })
    email!: string;

    @Prop({
        required: true,
        type: Array as () => IDropdownItem[],
        validator(value: any): boolean {
            return value.every((item: any) => ('text' in item
                && typeof item.text === 'string'));
        },
    })
    private items!: IDropdownItem[];

    get currentQuery() {
        return window.location.search;
    }
}
