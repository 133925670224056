
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ZendeskService, { ZendeskServiceS } from '@/modules/zendesk-chat/zendesk.service';
import CustomDropdown from './ui-kit/custom-dropdown.vue';

@Component({
    components: {
        CustomDropdown,
    },
})
export default class CiInfo extends Vue {
    @Inject(ZendeskServiceS) private zendeskService!: ZendeskService;

    get items() {
        return [
            {
                link: 'https://www.fornova.com/resources',
                text: 'Fornova Hub',
            },
            {
                link: 'https://fornova.zendesk.com/hc/en-us/categories/360003277637-Knowledge-Base-CI',
                text: 'Knowledge Base',
            },
            {
                action: () => {
                    this.zendeskService.openChat();
                },
                link: '',
                text: 'Contact Us',
            },
        ];
    }
}
